import payload_plugin_BwjP8ARzzD from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_LTFA89cjNS from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kRj4qL7546 from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3dlmwamigw from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8YtrOEg8gp from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kgNlurtVdU from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5Ou9jxATS3 from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Lu1KBhElMx from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_87l1PENgwm from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_53oHI1IsTV from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_2iuMaS7lFp from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_pS1tH9KKXb from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import switch_locale_path_ssr_9duT6tfu0s from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxtjs+i18n-edge@9.1.3-28961985.166f5ae_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__m_eex2l45rp4uhifuphocsdzeoje/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_lA7ILoCnAs from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxtjs+i18n-edge@9.1.3-28961985.166f5ae_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__m_eex2l45rp4uhifuphocsdzeoje/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/route-locale-detect.js";
import i18n_8WOVzPQ0zD from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxtjs+i18n-edge@9.1.3-28961985.166f5ae_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__m_eex2l45rp4uhifuphocsdzeoje/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.js";
import plugin_kn3XtG8oDr from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt-directus@5.7.0_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import slideovers_unqAxR20bH from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_h67eu3gyem2rfirq6fvbx7i4oy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_YrtnbKLXTy from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_h67eu3gyem2rfirq6fvbx7i4oy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_x8tx0rTHY9 from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@_h67eu3gyem2rfirq6fvbx7i4oy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_tN72JH81xj from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_k29zxtyEav from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_te_l2cj2dwsj5nl24pd2nyhg23hta/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_SOTxJM0yk4 from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/plugins/auth.ts";
import plugin_k8Ydohca2T from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_iib5ua6ec4p2e3ynrsjviwu7au/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import ssg_detect_GNbHQKaTRF from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/@nuxtjs+i18n-edge@9.1.3-28961985.166f5ae_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__m_eex2l45rp4uhifuphocsdzeoje/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_BwjP8ARzzD,
  revive_payload_client_LTFA89cjNS,
  unhead_kRj4qL7546,
  router_3dlmwamigw,
  payload_client_8YtrOEg8gp,
  navigation_repaint_client_kgNlurtVdU,
  check_outdated_build_client_5Ou9jxATS3,
  chunk_reload_client_Lu1KBhElMx,
  cross_origin_prefetch_client_87l1PENgwm,
  plugin_vue3_53oHI1IsTV,
  components_plugin_zlvi6dcIsi,
  prefetch_client_2iuMaS7lFp,
  motion_pS1tH9KKXb,
  switch_locale_path_ssr_9duT6tfu0s,
  route_locale_detect_lA7ILoCnAs,
  i18n_8WOVzPQ0zD,
  plugin_kn3XtG8oDr,
  slideovers_unqAxR20bH,
  modals_YrtnbKLXTy,
  colors_x8tx0rTHY9,
  plugin_client_tN72JH81xj,
  plugin_k29zxtyEav,
  auth_SOTxJM0yk4,
  plugin_k8Ydohca2T,
  ssg_detect_GNbHQKaTRF
]