export default defineNuxtPlugin((nuxtApp) => {
    const auth = useState('auth', () => ({
      isAuthenticated: false,
      user: null,
      accessToken: null,
    }));
  
    const restoreAuth = () => {
      if (process.client) {
        const storedAuth = localStorage.getItem('authState');
        if (storedAuth) {
          auth.value = JSON.parse(storedAuth);
        }
      }
    };
  
    // Restore auth on initial client-side load
    if (process.client) {
      restoreAuth();
    }
  
    return {
      provide: {
        auth,
        restoreAuth,
      },
    };
  });