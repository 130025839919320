import validate from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__io_bw72nlqxx4jie2mzsjtdrd4bua/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-logged-in": () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/middleware/auth-logged-in.ts"),
  locale: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/middleware/locale.ts"),
  "template1-layout": () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/middleware/template1Layout.ts")
}