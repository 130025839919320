
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboard3mHlHpUNfxMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/dashboard.vue?macro=true";
import { default as indexhVgbFWkE0ZMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/index.vue?macro=true";
import { default as _91slug_93JBmOpauaX5Meta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/services/[slug].vue?macro=true";
import { default as indexKA8kZc2ZDZMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/index.vue?macro=true";
import { default as LoginpRsnpe09SMMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/Login.vue?macro=true";
import { default as privacy_45policyuPa5Yid7ZhMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/privacy-policy.vue?macro=true";
import { default as registerG224eXaYpDMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/register.vue?macro=true";
import { default as _91slug_93Qqbxl4zoUDMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/[slug].vue?macro=true";
import { default as indext1Ee6FO0P9Meta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/index.vue?macro=true";
import { default as contactmatr5yJbWiMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/contact.vue?macro=true";
import { default as indexiSqaETz0WhMeta } from "C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/index.vue?macro=true";
export default [
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    meta: dashboard3mHlHpUNfxMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___hu",
    path: "/dashboard",
    meta: dashboard3mHlHpUNfxMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/dashboard.vue")
  },
  {
    name: "homemaster___en",
    path: "/en/homemaster",
    meta: indexhVgbFWkE0ZMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/index.vue")
  },
  {
    name: "homemaster___hu",
    path: "/homemaster",
    meta: indexhVgbFWkE0ZMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/index.vue")
  },
  {
    name: "homemaster-services-slug___en",
    path: "/en/homemaster/services/:slug()",
    meta: _91slug_93JBmOpauaX5Meta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/services/[slug].vue")
  },
  {
    name: "homemaster-services-slug___hu",
    path: "/homemaster/services/:slug()",
    meta: _91slug_93JBmOpauaX5Meta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/homemaster/services/[slug].vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexKA8kZc2ZDZMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/index.vue")
  },
  {
    name: "index___hu",
    path: "/",
    meta: indexKA8kZc2ZDZMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/index.vue")
  },
  {
    name: "Login___en",
    path: "/en/Login",
    meta: LoginpRsnpe09SMMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/Login.vue")
  },
  {
    name: "Login___hu",
    path: "/Login",
    meta: LoginpRsnpe09SMMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/Login.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: privacy_45policyuPa5Yid7ZhMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/privacy-policy",
    meta: privacy_45policyuPa5Yid7ZhMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/privacy-policy.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerG224eXaYpDMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/register.vue")
  },
  {
    name: "register___hu",
    path: "/register",
    meta: registerG224eXaYpDMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/register.vue")
  },
  {
    name: "template1-blog-slug___en",
    path: "/en/template1/blog/:slug()",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/[slug].vue")
  },
  {
    name: "template1-blog-slug___hu",
    path: "/template1/blog/:slug()",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/[slug].vue")
  },
  {
    name: "template1-blog___en",
    path: "/en/template1/blog",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/index.vue")
  },
  {
    name: "template1-blog___hu",
    path: "/template1/blog",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/blog/index.vue")
  },
  {
    name: "template1-contact___en",
    path: "/en/template1/contact",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/contact.vue")
  },
  {
    name: "template1-contact___hu",
    path: "/template1/contact",
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/contact.vue")
  },
  {
    name: "template1___en",
    path: "/en/template1",
    meta: indexiSqaETz0WhMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/index.vue")
  },
  {
    name: "template1___hu",
    path: "/template1",
    meta: indexiSqaETz0WhMeta || {},
    component: () => import("C:/Users/info/Documents/fejlesztes8/flexi-solutions4/app/pages/template1/index.vue")
  }
]